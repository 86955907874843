import { useContext, useEffect, useState } from "react"
import { HtmlToPngConverter } from "../HtmlToPngConverter"
import { LandingContext } from "../../pages/LandingContext"
import { getReq } from "../../utils/apiCalls"


export const MarketingTools = () => {
    const { landingData } = useContext(LandingContext)
    const [loader, setLoader] = useState(true)
    const [imgsBase64, setImgsBase64] = useState({})

    useEffect(() => {
        getReq("https://youngstartup.io/api/db/website/dashboard/get_base_64_images")
            .then(res => res.json())
            .then((res) => setImgsBase64(res))
            .finally(() => { setLoader(false) })
    }, [])

    return (
        <div className='marketing-tools'>
            <h1>Guidelines</h1>
            <ul>
                <li>If your headshot or logo don't fit into the marketing images below, please re-upload in the "Featured Info" tab and follow the instructions found there.</li>
                <li><a href="https://docs.google.com/document/d/1SXf__Wcl_NF1O1ZX9G4bFt0kpLDoTOeE2ehlCLXhyOg/edit" target="_blank">Click here</a> for social media post suggestions.</li>
            </ul>
            <h1>Press Release</h1>
            <ul>
                <li><a href="https://docs.google.com/document/d/1Wb7b5YurC5q_WcNhzCBPIbsPUQ7jt2i-RGrRtOOIKy4/edit" target="_blank">Click here</a> to access a free press release template that you can use to promote your company, raise capital, and pitch to media outlets. This template is a valuable resource that you can personalize by simply inputting your company information.</li>
            </ul>
            <h1>Social Media Images</h1>
            {loader && <div className='loader-container'><div id='loader' /></div>}
            {!loader && <HtmlToPngConverter data={landingData.user.so_application} imgsBase64={imgsBase64} />}
        </div>
    )
}