import html2canvas from 'html2canvas';
import { imgs } from '../assets/imgs';
import { svgs } from '../assets/svgs';
import { useState } from 'react';

export const HtmlToPngConverter = ({ data, imgsBase64 }) => {
    const [imgClassName, setImgClassName] = useState('landscape')
    const convertToPng = (elementToConvert) => {
        const element = document.getElementById(elementToConvert)

        html2canvas(element)
            .then((canvas) => {
                const pngUrl = canvas.toDataURL('image/png')
                const downloadLink = document.createElement('a')
                downloadLink.href = pngUrl
                downloadLink.download = 'marketing_image.png'
                downloadLink.click()
            })
            .catch((error) => {
                console.error('Error converting to PNG:', error)
            })
    }

    const checkSize = (e) => {
        const width = e.target.naturalWidth
        const height = e.target.naturalHeight
        if (width > height) {
            setImgClassName('landscape')
        }
        else setImgClassName('portrait')
    }

    return (
        <div className='html-converter'>
            <div className='element element1' id="elementToConvert1">
                <img src={imgs.ShareImage1} alt="img" />
                <div className='profile'>
                    <img onLoad={(e) => checkSize(e)} className={imgClassName} src={imgsBase64.headshot} alt="img" />
                </div>
                <div className='name'>
                    <h1>{data.featured_presenter.firstname}</h1>
                    <h1> {data.featured_presenter.lastname}</h1>
                    <h2>{data.featured_presenter.jobtitle} </h2>
                </div>
                <div className='logo'>
                    <img src={imgsBase64.logo} alt="img" />
                </div>
            </div>
            <button onClick={() => convertToPng('elementToConvert1')}>Download {svgs.download}</button>
            {/* <div className='element element2' id="elementToConvert2" >
                <img src={imgs.ShareImage2} alt="img" />
                <div className='logo'>
                    <img src={imgsBase64.logo} alt="img" />
                </div>
            </div>
            <button onClick={() => convertToPng('elementToConvert2')}>Download {svgs.download}</button> */}
            <div className='element element3' id="elementToConvert3" >
                <img src={imgs.ShareImage3} alt="img" />
                <div className='logo'>
                    <img src={imgsBase64.logo} alt="img" />
                </div>
            </div>
            <button onClick={() => convertToPng('elementToConvert3')}>Download {svgs.download}</button>
            {/* <div className='element element4' id="elementToConvert4" >
                <img src={imgs.ShareImage4} alt="img" />
                <div className='logo'>
                    <img src={imgsBase64.logo} alt="img" />
                </div>
            </div>
            <button onClick={() => convertToPng('elementToConvert4')}>Download {svgs.download}</button> */}
            <div className='element element5' id="elementToConvert5" >
                <img src={imgs.ShareImage5} alt="img" />
                <div className='logo'>
                    <img src={imgsBase64.logo} alt="img" />
                </div>
            </div>
            <button onClick={() => convertToPng('elementToConvert5')}>Download {svgs.download}</button>
            <div className='element element6' id="elementToConvert6">
                <img src={imgs.TopInnovatorAward} alt="img" />
            </div>
            <button onClick={() => convertToPng('elementToConvert6')}>Download {svgs.download}</button>
        </div>
    )
}

