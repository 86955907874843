import { useContext, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { LandingContext } from '../../pages/LandingContext';
import { getMonthAndDayWithSuffix } from '../../utils/funcs';

import styles from './Header.module.css';
export const Header = () => {
   const { landingData } = useContext(LandingContext)
   const headers = {
      home: {
         h1: 'WELCOME',
         h2: 'Manage your Presenter Experience',
      },
      info: {
         h1: 'FEATURED INFO',
         h2: 'The below information will be featured on the Venture Summit website',
      },
      marketing_tools: {
         h1: 'MARKETING TOOLS',
         h2: 'Feel free to use any of the below to promote your participation in the summit!',
      },
      summary: {
         h1: 'SUMMARY OUTLINE',
         h2: 'The below information will be featured in the event guide. The Guide is distributed to investors at the conference, so make sure to go through all the fields and put in the most up-to-date information!',
      },
      presentation_upload: {
         h1: 'POWERPOINT UPLOAD',
         h2: 'This is the PowerPoint file you will use during your presentation. You can update the file before the deadline as many times as you like.',
      },
      assign: {
         h1: 'YOUR ATTENDEES',
         h2: `Your company has ${landingData.passes.length} ${landingData.passes.length > 1 ? "tickets" : "ticket"} available for your team to use to attend the Summit. Please assign them prior to your arrival for a smooth check-in experience.`,
      },
      coaching: {
         h1: 'COACHING',
         h2: 'The Featured Company Coaching Session is a venture bootcamp for presenting companies and invited VCs.',
      },
      guide: {
         h1: 'GUIDELINES & RECOMMENDATIONS',
         h2: 'General Info about the summit, schedule, venue location, matchmaking, presentation schedule. ',
      },
      presentation_schedule: {
         h1: 'PRESENTATION SCHEDULE',
         h2: 'Check back a week before the Summit to see your company’s time slot',
      }
   }
   const location = useLocation()
   const paths = location.pathname.split('/')
   const page = paths[paths.length - 1]
   const h2Ref = useRef(null);
   const h3Ref = useRef(null);

   useEffect(() => {
      function handleScroll() {
         const st = window.pageYOffset || document.documentElement.scrollTop;
         h2Ref.current.style.opacity = Math.min(1, 5000 / (st ** 2));
         if (h3Ref.current) h3Ref.current.style.opacity = Math.min(1, 5000 / (st ** 2));
      }
      window.addEventListener('scroll', handleScroll);
      return () => {
         window.removeEventListener('scroll', handleScroll);
      };
   }, []);

   const Deadline = () => {
      switch (page) {
         case 'home':
            break;
         case 'info':
            return <h3 ref={h3Ref}>
               <span>DEADLINE: <span>{getMonthAndDayWithSuffix(landingData.attendee_data.pres_dl_1)}</span> - </span>
               Any submissions after this date may not be taken into consideration
            </h3>
         case 'marketing_tools':
            break;
         case 'summary':
            return <h3 ref={h3Ref}>
               <span>DEADLINE: <span>{getMonthAndDayWithSuffix(landingData.attendee_data.pres_dl_1)}</span> - </span>
               Any Updates made after that date may not be included in the event guide.
            </h3>
         case 'presentation_upload':
            return <h3 ref={h3Ref}>
               <span>DEADLINE: <span>{getMonthAndDayWithSuffix(landingData.attendee_data.pres_dl_3)}</span> - </span>
               Any submissions after this date may not be taken into consideration
            </h3>
         case 'assign':
            return <h3 ref={h3Ref}>
               <span>DEADLINE: <span>{getMonthAndDayWithSuffix(landingData.attendee_data.pres_dl_2)}</span> - </span>
               Any tickets changed after this date will result with an extra charge of $50
            </h3>
         case 'coaching':
            return <h3 ref={h3Ref}>
               <span>DEADLINE: <span>{getMonthAndDayWithSuffix(landingData.attendee_data.pres_dl_1)}</span> - </span>
               Any submissions after this date may not be taken into consideration
            </h3>
         default:
            return <></>
      }
   }

   return (
      <div className={styles.header} >
         <h1>{headers[page].h1}</h1>
         <h2 ref={h2Ref}>{headers[page].h2}</h2>
         <Deadline />
      </div>
   )
}

