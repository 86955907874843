import { useContext, useState } from 'react';
import { patchData } from '../../utils/apiCalls';
import { SubmitButton } from '../SubmitButton';
import { ImageUploader } from '../ImageUploader';
import { LandingContext } from '../../pages/LandingContext';
import styles from './Info.module.css';

export const Info = () => {
   const { landingData, updateLandingData } = useContext(LandingContext)
   const [oneLiner, setOneLiner] = useState(landingData.user.so_application.one_liner || '');
   const [presenterInfo, setPresenterInfo] = useState(landingData.user.so_application.featured_presenter || {});


   const handelSubmit = (e) => {
      e.preventDefault();
      updateData({ one_liner: oneLiner, featured_presenter: presenterInfo })
   }

   const updateData = (payload) => {
      return patchData(`https://youngstartup.io/api/db/website/so_forms/update`, {
         body: payload
      }).then((res) => {
         if (res.success) {
            updateLandingData()
         } else {
            throw new Error(res.message)
         }
      })
   }

   const handleOneLiner = (e) => {
      setOneLiner(e.target.value);
   }

   const handlePresenterInfo = (e) => {
      setPresenterInfo({ ...presenterInfo, [e.target.name]: e.target.value });
   }

   return (
      <div className={styles.info}>
         <h1>Company Logo</h1>
         <ol>
            <li>.png or .jpg files only</li>
            <li>Please upload a dark logo on a white or transparent background</li>
            <li>Hi-Res, minimum 300x100 px</li>
         </ol>

         <form onSubmit={handelSubmit} >
            <ImageUploader source={landingData.user.so_application.logo_url} updateData={updateData} propName={'logo_url'} />
            <section className={styles.oneLiner}>
               <h1>Company One-Liner</h1>
               <p>Short company description (Max. 250 Characters)</p>
               <textarea
                  name="oneliner"
                  maxLength="250"
                  rows="3"
                  placeholder='description'
                  value={oneLiner}
                  onChange={handleOneLiner}
               />
            </section>
            <h1>Featured Person</h1>
            <p>This person will be featured on the company website beside the company name and one liner. ideally CEO or Founder.</p>
            <h2>Headshot</h2>
            <ol>
               <li>Aspect ratio of 1:1</li>
               <li>Make sure your face is centered in the middle</li>
               <li>Hi-Res, minimum 300x300 px</li>
            </ol>
            <ImageUploader source={landingData.user.so_application.headshot_url} updateData={updateData} propName={'headshot_url'} />
            <section className={styles.nameTitle}>
               <h3>First Name</h3>
               <input
                  type="text"
                  name="firstname"
                  placeholder='first name'
                  value={presenterInfo.firstname}
                  onChange={handlePresenterInfo}
               />
               <h3>Last Name</h3>
               <input
                  type="text"
                  name="lastname"
                  placeholder='first name'
                  value={presenterInfo.lastname}
                  onChange={handlePresenterInfo}
               />
               <h3>Job Title</h3>
               <input
                  type="text"
                  name="jobtitle"
                  placeholder='job title'
                  value={presenterInfo.jobtitle}
                  onChange={handlePresenterInfo}
               />
            </section>

            <button>
               <SubmitButton
                  color='#0095C3' bgColor='#0F1A28' borderColor='white'
                  clickable={true || oneLiner !== landingData.user.so_application.one_liner}
               />
            </button>
         </form>
         <section className={styles.progress}></section>
         <section className={styles.upload}></section>
      </div>
   )
}
