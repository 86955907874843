import { useContext, useEffect, useState } from "react"
import { svgs } from "../../assets/svgs"
import { SOFormContext } from "../../pages/SOForm"
import { getData, patchData } from "../../utils/apiCalls"


export const EventDialog = () => {
    const { SOFormUser, setSOFormUser } = useContext(SOFormContext)
    const [loader, setLoader] = useState(true)

    useEffect(() => {
        getData('https://youngstartup.io/api/website_{startup}/event_popup_data')
            .then((res) => {
                setSOFormUser({ ...SOFormUser, upcomingEventData: res })
            })
            .finally(() => { setLoader(false) })
    }, [])

    const handelSubmit = () => {
        if (!SOFormUser.formData.presenting_virtually) {
            setSOFormUser({ ...SOFormUser, msg: 'You must select an option.' })
            return
        }
        patchData(`https://youngstartup.io/api/db/website/so_forms/update`, { body: SOFormUser.formData })
        setSOFormUser({ ...SOFormUser, currStage: 'PriceDialog' })
    }

    const handelChecked = (e) => {
        setSOFormUser({
            ...SOFormUser,
            msg: '',
            formData: {
                ...SOFormUser.formData,
                presenting_virtually: e.target.name === 'inperson'
                    ? (e.target.checked ? 'no' : '')
                    : (e.target.checked ? 'yes' : '')
            }
        })
    }

    return (
        <div className='event-dialog dialog'>
            {loader && <div className='loader-container'><div id='loader' /></div>}
            {!loader && <>
                <h1>Top Innovator Application</h1>
                <p>You are now applying for the upcoming Venture Summit ({SOFormUser.upcomingEventData.dates})</p>
                {/* <p>You are now applying for the upcoming Venture Summit (Sept. 4th & 5th)</p> */}
                <img src="https://youngstartup.io/storage/uploads/website_content/current_event/logo_dark.png" alt="logo" />
                <section>
                    <p>I would like to present</p>
                    <div>
                        <div className='input'>
                            <label htmlFor="inperson">In Person</label>
                            <input type="checkbox" name="inperson" id="inperson" onChange={handelChecked} checked={SOFormUser.formData.presenting_virtually === 'no'} />
                        </div>
                        <div className='input'>
                            <label htmlFor="virtually">Virtually</label>
                            <input type="checkbox" name="virtually" id="virtually" onChange={handelChecked} checked={SOFormUser.formData.presenting_virtually === 'yes'} />
                        </div>
                    </div>
                </section>
                {SOFormUser.msg && <div className="msg">{SOFormUser.msg}</div>}
                {!SOFormUser.msg && <p>You can change your selection later.</p>}
                <div className='btn-container'>
                    <button onClick={handelSubmit} className='btn'>Continue</button>
                </div>
            </>}
        </div>
    )
}





