import ShareImage1 from "./imgs/SHAREIMAGE1.png"
import ShareImage2 from "./imgs/SHAREIMAGE2.png"
import ShareImage3 from "./imgs/SHAREIMAGE3.png"
import ShareImage4 from "./imgs/SHAREIMAGE4.png"
import ShareImage5 from "./imgs/SHAREIMAGE5.png"
import TopInnovatorAward from "./imgs/TopInnovatorAward.png"

export const imgs = {
    ShareImage1,
    ShareImage2,
    ShareImage3,
    ShareImage4,
    ShareImage5,
    TopInnovatorAward,
}